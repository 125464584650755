import React from 'react';

import { Section } from '.';
import { Container, Row, Fiddy } from '../utils';
import Img from '../img/design.png';

export const Services = () => {
  return (
    <Section bgColor='white' id='services' tabIndex='0'>
      <Container>
        <Row reverseRow>
          <Fiddy>
            <h2>Webbdesign & webbutveckling</h2>
            <p>
              Behöver du en hemsida till din firma? En personlig hemsida? Eller
              kanske behöver du hjälp med din nuvarande? Vad det än är, så är
              jag här för att hjälpa.
            </p>
          </Fiddy>

          <Fiddy>
            <img src={Img} alt='Illustration: Woman by screen' />
          </Fiddy>
        </Row>
      </Container>
    </Section>
  );
};
