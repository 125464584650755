import styled from 'styled-components';
import React from 'react';
import { Section } from '../layouts';
import { Container, Row, Fiddy, below } from '../utils';
import Img from '../img/screen.png';

export const Hero = () => {
  return (
    <SectionHero bgColor='#fff7ce' tabIndex='0'>
      <Container>
        <Row>
          <Fiddy>
            <HeroH1>
              Hallå<ThinSpan>.</ThinSpan>
            </HeroH1>
            <HeroH2>Mitt namn är Per</HeroH2>
            <HeroP>Jag designar och utvecklar webbsidor</HeroP>
          </Fiddy>
          <Fiddy>
            <img src={Img} alt='Hero' />
          </Fiddy>
        </Row>
      </Container>
    </SectionHero>
  );
};

const SectionHero = styled(Section)`
  min-height: 65vh;
  display: flex;
  align-items: center;
  ${below.s`
    text-align: center;
  `}
`;

const HeroH1 = styled.h1`
  font-size: 125px;
  font-weight: 900;
  line-height: 100px;

  ${below.s`
    font-size: 80px;
    line-height: 70px;
  `}
`;

const HeroH2 = styled.h2`
  font-size: 36px;
  font-weight: 200;
`;

const HeroP = styled.p`
  font-weight: 300;
`;

const ThinSpan = styled.span`
  font-weight: 200;
`;
