import { css } from 'styled-components';

/////////////////////////////////////////////////////
//MEDIA QUERY SAVE DIS
/////////////////////////////////////////////////////
const size = {
  xs: 400,
  s: 768,
  m: 960,
  l: 1140,
};

export const above = Object.keys(size).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (min-width: ${size[label]}px) {
      ${css(...args)}
    }
  `;

  return acc;
}, {});

export const below = Object.keys(size).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (max-width: ${size[label]}px) {
      ${css(...args)}
    }
  `;

  return acc;
}, {});
