import React from 'react';

export const NavItems = ({ onSelect }) => {
  return (
    <ul>
      <li>
        <a href='#services' onClick={onSelect}>
          Tjänster
        </a>
      </li>
      <li>
        <a href='#about' onClick={onSelect}>
          Om Mig
        </a>
      </li>
      <li>
        <a href='#contact' onClick={onSelect}>
          Kontakt
        </a>
      </li>
    </ul>
  );
};
