import React from 'react';
import styled from 'styled-components';
import logo from '../img/brand.png';
import { elevation, brandColor, below } from '../utils';
import { NavItems, Wrapper, SideDrawerBurger } from '../elements';

export const Header = () => {
  return (
    <AppHeader>
      <Wrapper>
        <FlexNav>
          <div>
            <a href='/'>
              <img src={logo} alt='logo' className='logo' />
            </a>
          </div>

          <Spacer />

          <MainNav>
            <NavItems />
          </MainNav>

          <SideDrawerBurger />
        </FlexNav>
      </Wrapper>
    </AppHeader>
  );
};

const FlexNav = styled.nav`
  display: flex;
`;

const MainNav = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: center;

  ul {
    display: flex;
    list-style: none;
    padding: 0;

    a {
      color: white;
      text-decoration: none;
      text-transform: uppercase;
      padding: 10px;
    }
  }

  ${below.s`
    display: none;
  `}
`;

const Spacer = styled.div`
  flex: 1;
`;

const AppHeader = styled.header`
  background: ${brandColor.shadeDark};
  padding: 10px 5%;
  width: 100%;
  ${elevation[0]};
  .logo {
    width: 64px;
  }
`;
