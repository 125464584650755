import React, { useContext } from 'react';
import styled from 'styled-components';
import { above } from '../utils';

import { DrawerContext } from '../App';

export const SideDrawerBurger = () => {
  const drawerInfo = useContext(DrawerContext);

  return (
    <BurgerButton onClick={drawerInfo.setToggle}>
      <BurgerLine />
      <BurgerLine />
      <BurgerLine />
    </BurgerButton>
  );
};

const BurgerLine = styled.div`
  width: 24px;
  height: 2px;
  background: white;
  align-self: flex-end;
`;

const BurgerButton = styled.button`
  height: 24px;
  width: 36px;
  display: flex;
  align-self: center;
  background: transparent;
  border: none;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding: 0;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  div:nth-child(2) {
    width: 20px;
  }

  ${above.s`
    display: none;
  `}
`;
