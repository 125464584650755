import styled, { css } from 'styled-components';
import { below } from '../utils';

const bgColor = css`
  background-color: ${({ bgColor }) => bgColor || 'red'};
`;

export const Section = styled.section`
  padding: 100px 15px;
  ${bgColor};

  ${below.s`
    padding: 50px 15px;
  `}
`;
