export const purple = "#524763";
export const teal = "#82D8D8";

// Brand colors
export const brandColor = {
  brandPrimary: "#D2514A",
  shadeLight: "#EBEBE6",
  shadeDark: "#28252E",
  accentLight: "#D0A066",
  accentDark: "#D589A7"
};

// Component colors
export const compColor = {
  white: "hsl(0, 0%, 100%)",
  black: "hsl(0, 0%, 4%)",
  light: "hsl(0, 0%, 96%)",
  dark: "hsl(0, 0%, 21%)",
  primary: "hsl(171, 100%, 41%)",
  link: "hsl(217, 71%, 53%)",
  info: "hsl(204, 86%, 53%)",
  success: "hsl(141, 71%, 48%)",
  warning: "hsl(48, 100%, 67%)",
  danger: "hsl(348, 100%, 61%)"
};

// Social Colors
export const socialColor = {
  twitter: "#00aced",
  facebook: "#3b5998"
};
