import React from 'react';
import styled from 'styled-components';

const StyledFooter = styled.footer`
  background-color: #e45f00;
  color: rgba(255, 255, 255, 0.9);
  padding: 2px;
  text-align: center;
  p {
    font-size: 12px;
  }

  a {
    color: inherit;
    text-decoration: none;
  }
`;

export const Footer = () => {
  return (
    <StyledFooter tabIndex='0'>
      <p>
        Design and development by Munke Design{' '}
        <span role='img' aria-label='Love Letter Emoji'>
          🔥
        </span>{' '}
        <a href='https://twitter.com/unDraw_co'>
          Illustrations by @ninalimpi (unDraw)
        </a>
      </p>
    </StyledFooter>
  );
};
