import React, { useContext } from 'react';
import styled, { css } from 'styled-components';
import { brandColor } from '../utils';
import { NavItems } from '../elements';

import { DrawerContext } from '../App';

export const SideDrawer = () => {
  const { toggle, setToggle } = useContext(DrawerContext);

  const toggleDrawer = () => setToggle(!toggle);

  return (
    <Drawer open={toggle}>
      <CloseButton onClick={toggleDrawer}>X</CloseButton>
      <NavItems onSelect={toggleDrawer} />
    </Drawer>
  );
};

const Drawer = styled.nav`
  height: 100%;
  width: 100%;
  background: ${brandColor.shadeDark};
  position: fixed;
  top: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
  transition: all 300ms;
  transform: translateY(100px);

  ${({ open }) =>
    open &&
    css`
      opacity: 1;
      visibility: visible;
      transform: translateY(0);
    `}

  ul {
    margin: 0;
    padding: 0;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    list-style: none;

    li {
      text-transform: uppercase;
      padding: 10px;
    }
  }

  a {
    color: white;
    text-decoration: none;
    font-size: 30px;

    &:hover {
      text-decoration: underline;
    }
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  border: none;
  padding: 20px;
  cursor: pointer;
  color: white;
  background: transparent;
  font-size: 2.5em;
  font-family: monospace;

  &:focus {
    outline: none;
  }
`;
