import React, { useState, createContext } from 'react';
import GlobalStyle from './Global';
import { Header, Hero, Services, About, Contact, Footer } from './layouts';
import { SideDrawer } from './elements';

/////////////////////////////////////////////////////

export const DrawerContext = createContext();

const App = () => {
  const [toggle, setToggle] = useState(false);

  return (
    <DrawerContext.Provider value={{ setToggle, toggle }}>
      <div style={{ height: '100%' }}>
        <GlobalStyle />
        <Header />
        <SideDrawer />
        <main>
          <Hero />
          <Services />
          <About />
          <Contact />
        </main>
        <Footer />
      </div>
    </DrawerContext.Provider>
  );
};

export default App;
