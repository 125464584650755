import styled, { css } from "styled-components";
import { Button } from "./Buttons";
import { elevation } from "../utils";

const bgColor = css`
  background-color: ${({ bgColor }) => bgColor || "#fdfdfd"};
`;

export const Card = styled.div`
  ${bgColor};
  border-radius: 6px;
  padding: 15px;
  width: 100%;
  ${elevation[1]};
`;

const CardButton = styled(Button)`
  width: 100%;
`;

Card.Button = CardButton;
