import React from 'react';

import { Section } from '../layouts';
import { Container, Row, Fiddy } from '../utils';
import styled from 'styled-components';
import Img from '../img/envelope.png';

const WhiteContainer = styled(Container)`
  a,
  p,
  h2 {
    color: white;
    text-decoration: unset;
    font-style: initial;
  }

  a::before {
    content: '💌';
    font-style: initial;
    font-size: 20px;
  }
`;

export const Contact = () => {
  return (
    <Section bgColor='#111' id='contact' tabIndex='0'>
      <WhiteContainer>
        <Row reverseRow>
          <Fiddy>
            <h2>Kontakt</h2>
            <p>
              Intresserad i hur jag kan hjälpa dig? Tveka inte på att kontakta
              mig. Vill du boka ett telefonmöte är det bara att slänga iväg ett
              mail med ditt nummer/Skype/Discord så ringer jag omgående.
            </p>
            <br />
            <address>
              <a href='mailto:info@munkedesign.com'>info@munkedesign.com</a>
            </address>
          </Fiddy>
          <Fiddy>
            <img src={Img} alt='Hej' />
          </Fiddy>
        </Row>
      </WhiteContainer>
    </Section>
  );
};
