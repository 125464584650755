import { createGlobalStyle } from 'styled-components';
import { normalize } from 'polished';

const GlobalStyle = createGlobalStyle`
  ${normalize};

  html {
    box-sizing: border-box;
    scroll-behavior: smooth;
    height: 100%;
  }

  h1,h2,h3,h4,h5,h6,p,a {
    margin: 2px;
  }

  p {
    font-size: 18px;
  }

  *, *:before, *:after {
    box-sizing: inherit;
  }

  body {
    margin: 0;
    font-family: "Poppins",  'Segoe UI', monospace;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 100%;
    
  }


  img{
    max-width: 100%;
  }

`;

export default GlobalStyle;
