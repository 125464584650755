import { below } from '../utils';
import styled, { css } from 'styled-components';

export const Container = styled.div`
  max-width: 960px;
  margin: 0 auto;
`;

export const Row = styled.div`
  display: flex;

  ${props =>
    props.reverseRow &&
    css`
      flex-direction: row-reverse;
    `}

  ${below.s`
    flex-direction: column;
    ${props =>
      props.reverseColumn &&
      css`
        flex-direction: column-reverse;
      `}

    h2 {
      text-align: center 
    }
  `}
`;

export const Fiddy = styled.div`
  flex-basis: 50%;
  justify-content: center;
  display: flex;
  flex-direction: column;
  padding: 10px;
  align-self: center;

  h2 {
    font-size: 40px;

    ${below.s`
      font-size: 30px;
    `}
  }

  ${below.s`
    padding: 20px 10px;
  `}
`;
