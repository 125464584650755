import React from 'react';

import { Section } from '../layouts';
import { Container, Row, Fiddy } from '../utils';
import Img from '../img/about.png';

export const About = () => {
  return (
    <Section bgColor='#D2ECFF' id='about' tabIndex='0'>
      <Container>
        <Row>
          <Fiddy>
            <h2>Om mig</h2>
            <p>
              Mitt namn är Per. Jag är en frilansande webbutvecklare från
              Sverige. Jag designar samt utvecklar hemsidor för <i>alla.</i>
            </p>
            <br />
            <p>
              När jag inte skapar saker för nätet tycker jag om att spendera tid
              åt mina andra intressen: Träning, anatomi och musik.
            </p>
            <br />
            <p>
              <b>Flytande i:</b> HTML, CSS, JavaScript, Svenska och Engelska
            </p>
            <br />
            <p>
              <b>Studerar:</b> React och Swahili
            </p>
          </Fiddy>
          <Fiddy>
            <img src={Img} alt='Illustration: Man and technology items' />
          </Fiddy>
        </Row>
      </Container>
    </Section>
  );
};
