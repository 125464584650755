import styled from "styled-components";

export const H1 = styled.h1`
  font-size: 2rem;
  margin: 0px;
`;

export const H2 = styled.h2`
  font-size: 1.8rem;
  margin: 0px;
`;

export const H3 = styled.h3`
  font-size: 1.6rem;
  margin: 0px;
`;

export const H4 = styled.h4`
  font-size: 1.4rem;
  margin: 0px;
`;

export const H5 = styled.h5`
  font-size: 1.2rem;
  margin: 0px;
`;

export const H6 = styled.h6`
  font-size: 1rem;
  margin: 0px;
`;
