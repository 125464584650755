import styled from "styled-components";
import { applyStyleModifiers } from "styled-components-modifiers";
import { compColor, elevation } from "../utils";

const BUTTON_MODIFIERS = {
  // Sizing
  small: () => `
    font-size: 1rem;
    padding: 3px 10px;
  `,

  large: () => `
    font-size: 3rem;
    padding: 10px 10px;
  `,

  // Colors
  white: () => `
    background: ${compColor.white};
    color: black;
  `,
  black: () => `
    background: ${compColor.black};
  `,
  light: () => `
    background: ${compColor.light};
    color: black;
  `,
  dark: () => `
    background: ${compColor.dark};
  `,
  primary: () => `
    background: ${compColor.primary};
  `,
  link: () => `
    background: ${compColor.link};
  `,
  info: () => `
    background: ${compColor.info};
  `,
  success: () => `
    background: ${compColor.success};
  `,
  warning: () => `
    background: ${compColor.warning};
  `,
  danger: () => `
    background: ${compColor.danger};
  `
};

export const Button = styled.button`
  padding: 5px 10px;
  margin-bottom: 5px;
  border: none;
  border-radius: 4px;
  color: white;
  font-size: 2rem;
  cursor: pointer;
  transition: 0.25s ease box-shadow;
  background: ${compColor.info};

  ${elevation[1]};
  &:hover {
    ${elevation[2]};
  }

  ${applyStyleModifiers(BUTTON_MODIFIERS)};
`;
